import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import styled from 'styled-components'

import {
  validationSchemaContact,
  initialValuesContact,
} from './validationSchemaContact'
import { encode } from '../../utils/helpers'

import Dropdown from '../Dropdown'
import Input from '../Input'
import Textarea from '../Textarea'
import { Button } from '../Button'
import Success from './Success'

import { H1 } from '../../styled/Heading'
import { Intro } from '../../styled/Text'
import { Form, FormActions } from '../../styled/Forms'
import Contain from '../../styled/Contain'

const Contact = props => {
  const { title, intro, formName } = props
  const [success, setSuccess] = useState(false)

  return (
    <ContactWrapper>
      <Contain size="medium">
        <H1 as="h2">{title}</H1>
      </Contain>
      <Contain size="small">
        <Intro>{intro}</Intro>
        {!success ? (
          <Formik
            initialValues={initialValuesContact}
            validationSchema={validationSchemaContact}
            onSubmit={(values, { setSubmitting }) => {
              fetch('/?no-cache=1', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: encode({
                  'form-name': formName,
                  ...values,
                }),
              })
                .then(() => {
                  setSuccess(!success)
                  setSubmitting(false)
                })
                .catch(error => {
                  setSuccess(false)
                  setSubmitting(false)
                })
            }}
            render={({ isSubmitting, handleSubmit }) => (
              <Form
                name={formName}
                method="POST"
                onSubmit={handleSubmit}
                data-netlify="true"
                data-netlify-honeypot="bot-field"
              >
                {/* Needed for Netlify Forms to work properly */}
                {/* https://www.netlify.com/blog/2017/07/20/how-to-integrate-netlifys-form-handling-in-a-react-app/#step-2 */}
                <input type="hidden" name="form-name" value={formName} />

                <Dropdown
                  label="Aanhef"
                  name="aanhef"
                  placeholder="Aanhef"
                  options={['Dhr.', 'Mevr.']}
                  md="1 / 3"
                />

                <Input
                  label="Naam"
                  name="naam"
                  placeholder="Naam"
                  md="3 / -1"
                />

                <Input
                  label="Telefoon"
                  name="telefoonnummer"
                  placeholder="Type je telefoonnummer"
                  type="phone"
                  md="1 / 4"
                />

                <Input
                  label="E-mail"
                  name="emailadres"
                  placeholder="Type je e-mailadres"
                  type="email"
                  md="4 / -1"
                />

                <Dropdown
                  label="Onderwerp"
                  name="onderwerp"
                  placeholder="Selecteer een onderwerp"
                  options={[
                    'Ik zoek een partner',
                    'Ik wil priveles',
                    'Wanneer zijn de proeflessen',
                    'Workshop/demo boeken',
                    'Ik wil een ruimte bij jullie huren',
                    'Overige vragen',
                  ]}
                  md="1 / -1"
                />

                <Textarea
                  label="Opmerking"
                  name="opmerking"
                  placeholder="Type je opmerking"
                  md="1 / -1"
                />

                <FormActions>
                  <Button
                    type="submit"
                    buttonLabel="Versturen"
                    isDisabled={isSubmitting}
                  />
                </FormActions>
              </Form>
            )}
          />
        ) : (
          <Success />
        )}
      </Contain>
    </ContactWrapper>
  )
}

Contact.propTypes = {
  title: PropTypes.string.isRequired,
  intro: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
}

export default Contact

const ContactWrapper = styled.section`
  margin-top: 40px;

  @media (min-width: ${props => props.theme.layout.breakpoints.large}) {
    margin-top: 60px;
  }
`
