import * as Yup from 'yup'

export const initialValuesContact = {
  aanhef: '',
  naam: '',
  telefoonnummer: '',
  emailadres: '',
  onderwerp: '',
  opmerking: '',
}

export const validationSchemaContact = Yup.object().shape({
  naam: Yup.string()
    .min(2, 'Te kort')
    .max(50, 'Te lang')
    .required('Verplicht'),
  telefoonnummer: Yup.string()
    .matches(/^(?=.*[0-9])[- +()0-9]+$/, {
      message: 'Alleen cijfers',
      excludeEmptyString: true,
    })
    .min(8, 'Te kort')
    .required('Verplicht'),
  emailadres: Yup.string()
    .email('Vul een geldig e-mailadres in')
    .required('Verplicht'),
})
