import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Contain from '../styled/Contain'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Contact from '../components/forms/Contact'
import Banner from '../components/Banner'
import FAQ from '../components/Faq'
import bannerImage from '../images/proefles.jpg'

export default function ContactPage({ data }) {
  return (
    <Layout>
      <Contain>
        <Contact
          title="Contact"
          intro="Heb je vragen of suggesties? Neem dan contact met ons op door onderstaand formulier in te vullen."
          formName="contact"
        />
        <Banner
          title={data.markdownRemark.frontmatter.title}
          html={data.markdownRemark.html}
          buttonLabel="Bekijk agenda"
          image={bannerImage}
        />
        <FAQ
          currentPage="Contact"
          intro="Dit zijn de meestgestelde vragen over het rooster en de tarieven, hier kun je het juiste antwoord vinden."
        />
      </Contain>
    </Layout>
  )
}

export const Head = () => <SEO title="Contact" />

export const bannerContactQuery = graphql`
  query {
    markdownRemark(frontmatter: { path: { eq: "signup" } }) {
      html
      frontmatter {
        path
        title
      }
    }
  }
`

ContactPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      html: PropTypes.string.isRequired,
      frontmatter: PropTypes.shape({
        path: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }).isRequired,
    }),
  }).isRequired,
}
