import React from 'react'
import PropTypes from 'prop-types'
import { connect, getIn } from 'formik'

import { Small } from '../styled/Text'
import { Label, StyledTextarea } from '../styled/Forms'

const Textarea = props => {
  const { formik, label, name, type, placeholder, md } = props

  const { errors, touched, values, handleChange, handleBlur } = formik

  const hasErrors = getIn(errors, name)
  const isTouched = getIn(touched, name)
  const fieldValue = getIn(values, name)

  return (
    <Label
      key={`label-${name}`}
      htmlFor={name}
      color={isTouched && hasErrors && 'red'}
      md={{ gridColumn: md }}
    >
      {label}
      {isTouched && hasErrors && <Small>{hasErrors}</Small>}
      <StyledTextarea
        onChange={handleChange}
        onBlur={handleBlur}
        value={fieldValue}
        name={name}
        placeholder={placeholder}
        type={type}
      />
    </Label>
  )
}

Textarea.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string,
  md: PropTypes.string,
}

Textarea.defaultProps = {
  type: 'text',
}

export default connect(Textarea)
